import { basePageMixin } from '@/mixins/basePageMixin'
import { beforeRouteLeaveCommon } from './common'
import { currentScrollPosition } from '@/utils/ui'
import { gotoScrollPosition } from '@/utils/ui'

export const formPageMixin = {
  mixins: [basePageMixin],
  data () {
    return {
      formData: {},
      submitMessage: 'Guardando cambios...',
    }
  },
  methods: {
    async initStore () {
      await this.copyRouteParamsToPageStore()
      if (this.scrollPosition > 0) {
        gotoScrollPosition(this.scrollPosition)
      }
    },
    async clickSubmit (form) {
      if (await form.validate()) {
        this.$loading.showManual(this.submitMessage)
        try {
          // submit de la página donde se aplica el mixin
          await this.submitForm()
        } finally {
          this.$loading.hide()
        }
      } else {
        await this.formInvalid()
      }
    },
    async formInvalid () {
      // sobreescribir donde se aplica el mixin
    },
    async saveFormData (formData) {
      for (const fieldname of Object.keys(formData)) {
        await this.setStoreProperty(`formData@${fieldname}`, formData[fieldname])
      }
    },
    async beforeRouteLeaveBase (to, from, next) {
      if (!beforeRouteLeaveCommon(this, to, from, next)) {
        if (this.pageStoreName) {
          if (this.rememberScroll) {
            await this.setStoreProperty('scrollPosition', currentScrollPosition())
          } else {
            await this.dispatchStore('resetScroll')
          }
          if (!this.rememberState) {
            await this.dispatchStore('resetStore')
          }
        }
        next()
      }
    },
  }
}
