import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectEntradasSinSalida (Vue, idempleado) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idempleado', idempleado)
      .addGT('estado', 0)
      .addIsNull('fsalida')
    const resp = await Vue.$api.call('presencia.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async registrarEntrada (
    Vue, idempleado, latitud, longitud, observaciones, idpunto, idpuestoServicioServicio
  ) {
    const presencia = await Vue.$api.call('presencia.registrarEntrada', {
      idempleado: idempleado,
      latitud: latitud,
      longitud: longitud,
      observaciones: observaciones,
      idpunto: idpunto,
      idpuesto_servicio_servicio: idpuestoServicioServicio
    })
    return presencia.data.result.dataset[0]
  },
  async registrarSalida (Vue, idempleado, latitud, longitud, observaciones, idpunto) {
    const presencia = await Vue.$api.call('presencia.registrarSalida', {
      idempleado: idempleado,
      latitud: latitud,
      longitud: longitud,
      observaciones: observaciones,
      idpunto: idpunto
    })
    if (presencia.data.result && presencia.data.result.dataset) {
      return presencia.data.result.dataset[0]
    }
  },
  async selectFicheroPuestoServicioCount (Vue, idpuestoServicio, esTecnico, esComercial, esVigilante) {
    const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idpuestoServicio, TABLA.puesto_servicio.idtabla, esTecnico, esComercial, esVigilante)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter, wrapper: 'count' })
    return resp.data.result.dataset[0]
  },
}
