export const beforeRouteLeaveCommon = (that, to, from, next) => {
  if (that.loadingData) {
    next(false)
    return true
  } else {
    let showingDialog = false
    for (const dialog in that.showingDialogs) {
      if (that.showingDialogs[dialog]) {
        that.$set(that.showingDialogs, dialog, false)
        showingDialog = true
        next(false)
        break
      }
    }
    return showingDialog
  }
}
