import { decodeExtraQuery } from '@/utils/router'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { beforeRouteLeaveCommon } from './common'
import { hasViewPerm, hasInsertPerm, hasEditPerm, hasDeletePerm, hasPerm, permissions } from '@/utils/permissions'

export const basePageMixin = {
  data () {
    return {
      title: '',
      routeParams: null,
      routeQuery: null,
      loadingData: false,
      rememberState: false,
      rememberScroll: false,
      showingDialogs: {},
      pageLoaded: false,
    }
  },
  created () {
    this.routeParams = _.cloneDeep(this.$route.params)
    this.routeQuery = _.cloneDeep(this.$route.query)
    if (this.routeQuery.extra) {
      this.routeQuery.extra = decodeExtraQuery(this.routeQuery.extra)
    }
  },
  computed: {
    routeFromBrowserHistory () {
      // no mostrar alerts en el "back" del navegador
      return this.$route.meta.fromBrowserHistory
    },
    userPermissions: get('usuario/permisos'),
    permissions () {
      return permissions
    },
  },
  methods: {
    async copyRouteParamsToPageStore () {
      if (this.pageStoreName) {
        for (const routeParam in this.routeParams) {
          if (this.storeHasProperty(routeParam)) {
            await this.setStoreProperty(routeParam, this.routeParams[routeParam])
          }
        }
      }
    },
    async initStore () {
      // routeParams -> ¿existe en el store de la página? -> copiar valor
      // usado para: nextRoute, readonly
      await this.copyRouteParamsToPageStore()
    },
    storeHasProperty (property) {
      return property in this.$store.get(`${this.pageStoreName}`)
    },
    async setStoreProperty (property, value) {
      await this.$store.set(`${this.pageStoreName}/${property}`, value)
    },
    async dispatchStore (actionName, payload) {
      return await this.$store.dispatch(`${this.pageStoreName}/${actionName}`, payload)
    },
    hasViewPerm (perm) {
      return hasViewPerm(this.userPermissions, perm)
    },
    hasInsertPerm (perm) {
      return hasInsertPerm(this.userPermissions, perm)
    },
    hasEditPerm (perm) {
      return hasEditPerm(this.userPermissions, perm)
    },
    hasDeletePerm (perm) {
      return hasDeletePerm(this.userPermissions, perm)
    },
    hasPerm (perm) {
      return hasPerm(this.userPermissions, perm)
    },
    beforeRouteLeaveBase (to, from, next) {
      if (!beforeRouteLeaveCommon(this, to, from, next)) {
        next()
      }
    },
  }
}
